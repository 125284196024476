import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
const app = createApp(App);
import { getServerUrl } from './utils/methods'
router.beforeEach(async (to, from, next) => {
	try {
		await getServerUrl()
		next()
	} catch (error) {
		console.error(error)
		next(false)
	}
})

app.use(router); // 使用Vue Router插件
app.use(Antd)

app.mount('#app');
