import {createRouter, createWebHistory} from 'vue-router';
import WebListPage from './pages/WebList.vue';
import FeedbackPage from './pages/FeedbackPage.vue';
import CreateFeedbackPage from './pages/CreateFeedbackPage.vue';
import ChatPage from './pages/ChatPage.vue';
import ReferCodePage from './pages/ReferCodePage.vue';
import ReferenceRecordPage from './pages/ReferenceRecordPage.vue';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{path: '/', component: WebListPage, name: 'webList'},
		{path: '/weblist', component: WebListPage, name: 'webList'},
		{path: '/feedback', component: FeedbackPage, name: 'feedback'},
		{path: '/createFeedback', component: CreateFeedbackPage, name: 'createFeedback'},
		{path: '/chat', component: ChatPage, name: 'chat'},
		{path: '/referCodePage', component: ReferCodePage, name: 'referCodePage'},
		{path: '/referenceRecord', component: ReferenceRecordPage, name: 'referenceRecord'},
		{
			path: '/:pathMatch(.*)*',
			redirect: '/' // 重定向到默认路径
		}
	]
});

export default router;