<template>
	<div class="page-container chat-container" v-if="this.canShowContent === true">
		<div class="chat-page-header" ref="catInfoContainer">
			<div class="chat-page-header-label">反馈类型：</div>

			<div class="breadcrumb-container" v-if="this.topicRecord?.topicType.length>0">
				<div v-for="(item, index) in this.topicRecord?.topicType" :key="item.item" class="breadcrumb-item">
					<div class="breadcrumb-label breadcrumb-label-topic">{{ item.label }}</div>
					<img src="../assets/right-arrow-small.svg" class="breadcrumb-divider breadcrumb-divider-topic"
					     v-if="index < this.topicRecord?.topicType.length -1" alt="arrow"/>
				</div>
			</div>
			<div class="chat-page-header-item-container">
				<div class="chat-page-header-label">问题描述：</div>
				<div class="chat-page-header-content">{{ this.topicRecord?.userDescription }}</div>
			</div>


			<div class="chat-page-header-item-container">
				<div class="chat-page-header-label">工单状态：<span
					:style="this.getStateStyle(this.topicRecord)">{{ this.topicRecord?.topicState }}</span></div>
			</div>

			<div class="chat-page-header-item-container" v-if="this.topicRecord?.images.length>0">
				<div class="chat-page-header-label">工单图片：</div>
				<div class="uploaded-images-container">
					<template v-for="item in this.topicRecord?.images" :key="item">
						<div class="uploaded-image-container" @click="this.onImageClicked(item)">
							<img class="upload-btn-container-image-uploaded" :src="item" alt="uploaded"/>
						</div>
					</template>
				</div>
			</div>

			<div class="chat-list-container">
				<template v-for="item in this.chatList" :key="item">
					<ChatMsgContainer :chatItem="item"></ChatMsgContainer>
				</template>
			</div>
		</div>
		<div class="chat-input-container">
			<textarea v-model='this.chatContent' placeholder="请在此输入信息"></textarea>
			<div class="send-chat-btn" @click="this.onSendChat">发送</div>
		</div>
		<ImageViewer v-if="this.showImage === true" :imageUrl="this.imageUrl" @close="this.onImageViewerClosed()"/>
	</div>
	<div class="page-container" v-else>
		参数错误
	</div>
</template>

<script>
import {hideLoading, postRequestAsync, showLoading, showToast, verifyTicketSessionId} from "@/utils/methods";
import ChatMsgContainer from '../components/ChatMsgContainer.vue';
import ImageViewer from '../components/ImageViewer.vue';

export default {
	name: "ChatPage",
	components: {ChatMsgContainer, ImageViewer},
	data() {
		return {
			canShowContent: true,
			feedbackTopicId: null,
			chatList: [],
			topicRecord: null,
			chatContent: null,
			showImage: false,
			imageUrl: null
		}
	},
	async mounted() {
		await this.initPage()
		await this.getFeedbackDetail()
	},
	methods: {
		async initPage() {
			try {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showLoading', JSON.stringify({tag: 'initPage'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'showLoading',
				// 		tag: 'initPage'
				// 	}));
				// }
				else {
					showLoading()
				}
				this.ticketSessionId = this.$route.query.id;
				if (!this.ticketSessionId) {
					this.canShowContent = false
					return
				}
				this.feedbackTopicId = this.$route.query.topicId;
				if (!this.ticketSessionId) {
					this.canShowContent = false
				} else {
					const isSessionValid = await verifyTicketSessionId(this.ticketSessionId)
					if (isSessionValid === false) {
						this.canShowContent = false
					}
				}
			} finally {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'initPage'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'hideLoading',
				// 		tag: 'initPage'
				// 	}));
				// }
				else {
					hideLoading()
				}
			}

		},
		async getFeedbackDetail() {
			try {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showLoading', JSON.stringify({tag: 'getFeedbackDetail'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'showLoading',
				// 		tag: 'getFeedbackDetail'
				// 	}));
				// }
				else {
					showLoading()
				}
				const response = await postRequestAsync('customerServicePanel/getFeedbackTopicDetail', {
					ticketSessionId: this.ticketSessionId,
					feedbackTopicId: this.feedbackTopicId
				})
				if (response && response?.code === 200) {
					this.chatList = response.msg.chatList
					this.topicRecord = response.msg.topicRecord
				}

			} finally {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'getFeedbackDetail'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'hideLoading',
				// 		tag: 'getFeedbackDetail'
				// 	}));
				// }
				else {
					hideLoading()
				}
			}

		},
		getStateStyle(item) {
			if (!item) {
				return {
					'color': '#E6D5B8'
				}
			}
			if (item.topicState === '等待回复') {
				return {
					'color': 'red'
				}
			}
			if (item.topicState === '已回复') {
				return {
					'color': 'green'
				}
			}
			if (item.topicState === 'closed') {
				return {
					'color': '#E6D5B8'
				}
			}
		},
		async onSendChat() {
			try {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showLoading', JSON.stringify({tag: 'onSendChat'}))
				} else {
					showLoading()
				}
				const response = await postRequestAsync('customerServicePanel/sendClientFeedback', {
					ticketSessionId: this.ticketSessionId,
					feedbackTopicId: this.feedbackTopicId,
					chatContent: this.chatContent
				})
				if (response && response?.code === 200) {

					if (window.Android && typeof window.Android.onEvent === 'function') {
						window.Android.onEvent('showToast', JSON.stringify({msg: '发送成功'}))
					} else {
						showToast('发送成功')
					}

					this.chatContent = null
					await this.getFeedbackDetail()
					this.checkScrollAndScrollToBottom()
				} else {
					if (window.Android && typeof window.Android.onEvent === 'function') {
						window.Android.onEvent('chatSendFail', '')
					} else {
						if (window.Android && typeof window.Android.onEvent === 'function') {
							window.Android.onEvent('showToast', JSON.stringify({msg: '发送失败'}))
						} else {
							showToast('发送失败')
						}
					}
				}
			} finally {

				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'onSendChat'}))
				} else {
					hideLoading()
				}
			}
		},
		checkScrollAndScrollToBottom() {
			const element = this.$refs.catInfoContainer;
			if (!element) return;

			const hasScrollbar = element.scrollHeight > element.clientHeight;

			if (hasScrollbar) {
				this.$nextTick(() => {
					element.scrollTop = element.scrollHeight - element.clientHeight;
				});
			}
		},
		onImageClicked(imageUrl) {
			if (!imageUrl) {
				return
			}
			this.imageUrl = imageUrl
			this.showImage = true
		},
		onImageViewerClosed() {
			this.imageUrl = null
			this.showImage = false
		}
	}
}
</script>

<style scoped>
.chat-container {
	overflow-y: hidden !important;
	height: 100% !important;
	padding: 0 !important;
}

.chat-page-header {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: start;
	flex: 1;
	padding: 1em;
	box-sizing: border-box;
	overflow-y: auto;
}

.chat-page-header-label {
	font-size: 1.2em;
	color: rgb(230, 213, 184, 0.6);
	line-height: 1;
	margin-bottom: 0.4em;
}

.chat-page-topic-label {
	font-size: 1.4em;
	color: var(--white-color);
	line-height: 1;
}

.chat-page-header-content {
	font-size: 1.4em;
	color: var(--white-color);
	line-height: 1.2;
	margin-bottom: 0.4em;
	text-align: start;
}

.chat-page-header-item-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: start;
	margin-bottom: 1em;
}

.breadcrumb-label-topic {
	font-size: 1.4em !important;
}

.breadcrumb-divider-topic {
	width: 1.6em !important;
}

.chat-list-container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.chat-input-container {
	width: 100%;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	align-items: start;
	padding: 1em;
}

textarea {
	flex: 1;
	background-color: transparent;
	border: 1px solid rgba(255, 137, 0, 1);
	border-radius: 4px;
	font-size: 1.4em;
	color: rgba(255, 137, 0, 1);
	min-height: 6em;
}

.send-chat-btn {
	background-color: rgba(255, 137, 0, 1);
	padding: 0.4em 0.4em;
	font-size: 1.6em;
	color: #1f1f35;
	border-radius: 0.2em;
	margin-left: 0.4em;
	line-height: 1;
}

.send-chat-btn:active {
	transform: scale(0.9);
}

</style>