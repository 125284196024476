<template>
	<router-view></router-view>
</template>

<script>

export default {
	name: 'App',
	components: {},
}
</script>

<style>
:root {
	--primary-color: #ff8900;
	--background-color: #1f1f35;
	--white-color: rgb(230, 213, 184);
	--red-color: #e45826;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	height: 100%;
	overflow-y: auto;
}

body {
	margin: 0;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: var(--background-color) !important;
}

.page-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1em;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	color: var(--white-color);
}

.breadcrumb-container {
	width: 100%;
	text-align: start;
	margin-bottom: 12px;
	display: flex;
	flex-wrap: wrap;
}

.breadcrumb-item {
	width: fit-content;
	color: rgba(230, 213, 184, 1);
	font-size: 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 4px;
}


.breadcrumb-divider {
	width: 16px;
}

.breadcrumb-label {
	width: fit-content;
	display: inline-block;
	white-space: nowrap;
	line-height: 1;
	font-size: 1.2em;
}

.uploaded-image-container {
	border: 1px solid rgba(230, 213, 184, 0.5);
	width: 10em;
	height: 10em;
	border-radius: 0.2em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 0.1em;
	box-sizing: border-box;
	margin-right: 0.4em;
	position: relative;
}

.upload-btn-container-image-uploaded {
	max-width: 100%;
	max-height: 100%;
}


.uploaded-images-container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	box-sizing: border-box;
}

.upload-btn-container-image-uploaded {
	max-width: 100%;
	max-height: 100%;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
	background: transparent !important;
	border: 1px solid #E6D5B8 !important;
	color: #E6D5B8 !important;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
	color: #E6D5B8 !important;
}

.ant-pagination-item a {
	color: #E6D5B8 !important;
}

.ant-pagination-item-active a {
	background-color: #E6D5B8 !important;
	color: #2c3e50 !important;;
}

.ant-pagination-item {
	background: transparent !important;
	border: 1px solid #E6D5B8 !important;
	color: #E6D5B8 !important;
}

</style>
