<template>
	<div class="page-container" v-if="this.canShowContent === true">
		<div class="create-feedback-title">请选择反馈的问题类型</div>
		<div class="create-feedback-sub-title">准确详细的反馈将优先回复，信息不全/不准确的工单将不予回复。</div>
		<hr>
		<div class="breadcrumb-container" v-if="this.stepFlow.length>0">
			<div v-for="(item, index) in this.stepFlow" :key="item.item" class="breadcrumb-item">
				<div class="breadcrumb-label">{{ item.label }}</div>
				<img src="../assets/right-arrow-small.svg" class="breadcrumb-divider"
				     v-if="index < this.stepFlow.length -1" alt="arrow"/>
			</div>
		</div>
		<div class="topic-list-container">
			<div v-for="item in this.getCurrentTopicList" :key="item.key" class="feedback-topic-card"
			     @click="onTopicClicked(item)">
				<div class="feedback-topic-label">{{ item.label }}</div>
				<img src="../assets/right-arrow.svg" alt="right arrow">
			</div>
		</div>
		<p v-if="this.getCurrentSelectedItem && this.getCurrentSelectedItem?.solution" class="feedback-topic-solution">
			{{ this.getCurrentSelectedItem.solution }}
		</p>
		<div v-if="this.getCurrentSelectedItem && this.getCurrentSelectedItem?.additional"
		     class="feedback-topic-description">
			{{ this.getCurrentSelectedItem.additional }}
		</div>
		<textarea v-if="this.getCurrentSelectedItem && !this.getCurrentSelectedItem.next"
		          placeholder="请在此输入反馈信息" rows="5" v-model='this.ticketContent'/>

		<div class="feedback-add-attachments" v-if="this.showSubmit()">
			<div class="feedback-add-attachments-label">添加图片：</div>

			<div class="uploaded-images-container">
				<template v-for="item in this.ticketImageList" :key="item">
					<div class="uploaded-image-container">
						<img class="uploaded-delete-image" src="../assets/close.svg" alt="delete img"
						     @click="this.onDeleteImage(item)">
						<img class="upload-btn-container-image-uploaded" :src="item" alt="uploaded"
						     @click="this.onImageClicked(item)"/>
					</div>
				</template>
			</div>

			<div class="upload-btn-container" @click="this.onUploadImage" v-if="this.inUploadingImage === false">
				<img class="upload-btn-container-image" src="../assets/plus_white.svg" alt="plus">
				<div class="upload-btn-container-text">点击添加</div>
			</div>

			<div class="upload-progress-bar-container" v-if="this.inUploadingImage === true">
				<div class="upload-progress-text">正在上传 {{ this.uploadingProgressPercentage }}%</div>
				<div class="upload-progress-bar-bg">
					<div class="upload-progress-bar" :style="this.getProgressBarStyle()"/>
				</div>
			</div>

			<input type="file" ref="fileInput" @change="handleFileChange" style="display: none"
			       accept="image/png, image/jpeg">
		</div>

		<div class="feedback-btn-container" v-if="this.stepFlow.length > 0">
			<div class="feedback-last-step" @click="onLastStep()">上一步</div>
			<div class="feedback-submit-btn" v-if="this.showSubmit()" @click="onSubmitFeedback()">提交</div>
		</div>

		<ImageViewer v-if="this.showImage === true" :imageUrl="this.imageUrl" @close="this.onImageViewerClosed()"/>
		<ConfirmModal v-if="this.showConfirmModal === true" :confirmText="'确认删除图片？'"
		              @confirm="this.confirmDeleteImage()" @cancel="this.onModalCancel()"/>
	</div>
	<div class="page-container" v-else>
		参数错误
	</div>
</template>
<script>

import {questionTree} from "@/utils/constants";
import {
	showToast,
	uploadFile,
	verifyTicketSessionId,
	checkFile,
	postRequestAsync,
	hideLoading,
	showLoading
} from "@/utils/methods";
import ImageViewer from "@/components/ImageViewer.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";

export default {
	name: "CreateFeedbackPage",
	components: {ImageViewer, ConfirmModal},
	async mounted() {
		await this.initPage()

	},
	data() {
		return {
			stepFlow: [],
			ticketImageList: [],
			inUploadingImage: false,
			uploadingProgressPercentage: 0,
			ticketSessionId: null,
			canShowContent: true,
			ticketContent: '',
			showImage: false,
			imageUrl: null,
			showConfirmModal: false,
			waitForDeleteUrl: null
		}
	},
	computed: {
		getCurrentSelectedItem() {
			if (!Array.isArray(this.stepFlow) || this.stepFlow.length === 0) {
				return null
			}
			return this.stepFlow[this.stepFlow.length - 1]
		},
		getCurrentTopicList() {
			if (!Array.isArray(this.stepFlow) || this.stepFlow.length === 0) {
				return questionTree
			}
			return this.stepFlow[this.stepFlow.length - 1].next
		}
	},
	methods: {
		async initPage(isNew = true) {
			if (window.Android && typeof window.Android.onEvent === 'function') {
				window.Android.onEvent('showLoading', JSON.stringify({tag: 'initPage'}))
			} else {
				showLoading()
			}
			try {
				this.ticketImageList = []
				this.ticketId = null
				this.ticketContent = ''
				if (isNew === true) {
					this.ticketSessionId = this.$route.query.id;
					if (!this.ticketSessionId) {
						this.canShowContent = false
					} else {
						const isSessionValid = await verifyTicketSessionId(this.ticketSessionId)
						if (isSessionValid === false) {
							this.canShowContent = false
						}
					}
				}
			} catch (e) {
				console.log(e.message)
			} finally {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'initPage'}))
				} else {
					hideLoading()
				}
			}

		},
		getProgressBarStyle() {
			return {
				'width': this.uploadingProgressPercentage + '%'
			}
		},
		onTopicClicked(item) {
			if (!this.stepFlow) {
				this.stepFlow = []
			}
			this.stepFlow.push(item)
		},
		async onLastStep() {
			await this.initPage(false)
			this.stepFlow.pop();
		},
		showSubmit() {
			if (!Array.isArray(this.stepFlow) || this.stepFlow.length === 0) {
				return false
			}

			return !this.getCurrentSelectedItem.next;

		},
		onUploadImage() {
			if (this.ticketImageList.length >= 4) {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showToast', JSON.stringify({msg: '达到工单图片上传上限'}))
				}
					// else if (window.chrome && window.chrome.webview) {
					// 	window.chrome.webview.postMessage(JSON.stringify({
					// 		type: 'showToast',
					// 		msg: '达到工单图片上传上限'
					// 	}));
				// }
				else {
					showToast('达到工单图片上传上限')
				}
				return
			}
			this.$refs.fileInput.click();
		},
		async handleFileChange(event) {

			const file = event.target.files[0];
			if (!file) {
				return
			}
			const verifyResult = checkFile(file)

			if (verifyResult === 'FILE_NOT_SUPPORT') {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showToast', JSON.stringify({msg: '支持的文件类型：png, jpg'}))
				}
					// else if (window.chrome && window.chrome.webview) {
					// 	window.chrome.webview.postMessage(JSON.stringify({
					// 		type: 'showToast',
					// 		msg: '支持的文件类型：png, jpg'
					// 	}));
				// }
				else {
					showToast('支持的文件类型：png, jpg')
				}
				return
			}
			if (verifyResult === 'FILE_TOO_LARGE') {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showToast', JSON.stringify({msg: '文件不大于5M'}))
				}
					// else if (window.chrome && window.chrome.webview) {
					// 	window.chrome.webview.postMessage(JSON.stringify({
					// 		type: 'showToast',
					// 		msg: '文件不大于5M'
					// 	}));
				// }
				else {
					showToast('文件不大于5M')
				}
				return
			}

			if (file) {
				const formData = new FormData();
				formData.append('file', file);
				formData.append('ticketId', this.ticketId);
				formData.append('ticketSessionId', this.ticketSessionId);
				this.inUploadingImage = true
				await uploadFile('customerServicePanel/uploadImage', formData, this.updateUploadImageInProgress, this.updateUploadImageSuccess, this.updateUploadImageFailed)
			}
		},
		updateUploadImageInProgress(percentage) {
			this.uploadingProgressPercentage = percentage
		},
		updateUploadImageSuccess(data) {
			if (window.Android && typeof window.Android.onEvent === 'function') {
				window.Android.onEvent('showToast', JSON.stringify({msg: '上传成功'}))
			}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'showToast',
				// 		msg: '上传成功'
				// 	}));
			// }
			else {
				showToast('上传成功')
			}
			this.inUploadingImage = false
			if (data?.msg?.ticketId) {
				this.ticketId = data?.msg?.ticketId
			}
			if (data?.msg?.imageUrlList) {
				this.ticketImageList = data?.msg?.imageUrlList
			}
		},
		updateUploadImageFailed() {
			if (window.Android && typeof window.Android.onEvent === 'function') {
				window.Android.onEvent('showToast', JSON.stringify({msg: '上传失败'}))
			}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'showToast',
				// 		msg: '上传失败'
				// 	}));
			// }
			else {
				showToast('上传失败')
			}
			this.inUploadingImage = false
		},
		async onSubmitFeedback() {
			if (!this.ticketSessionId) {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showToast', JSON.stringify({msg: '参数错误'}))
				}
					// else if (window.chrome && window.chrome.webview) {
					// 	window.chrome.webview.postMessage(JSON.stringify({
					// 		type: 'showToast',
					// 		msg: '参数错误'
					// 	}));
				// }
				else {
					showToast('参数错误')
				}
				return
			}
			if (!this.ticketContent) {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showToast', JSON.stringify({msg: '反馈信息不能为空'}))
				}
					// else if (window.chrome && window.chrome.webview) {
					// 	window.chrome.webview.postMessage(JSON.stringify({
					// 		type: 'showToast',
					// 		msg: '反馈信息不能为空'
					// 	}));
				// }
				else {
					showToast('反馈信息不能为空')
				}
				return
			}

			const topicType = []
			for (const item of this.stepFlow) {
				topicType.push({
					key: item.key,
					label: item.label
				})
			}
			try {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showLoading', JSON.stringify({tag: 'onSubmitFeedback'}))
				}
					// else if (window.chrome && window.chrome.webview) {
					// 	window.chrome.webview.postMessage(JSON.stringify({
					// 		type: 'showLoading',
					// 		tag: 'onSubmitFeedback'
					// 	}));
				// }
				else {
					showLoading()
				}
				const response = await postRequestAsync('customerServicePanel/createFeedbackTopic', {
					ticketSessionId: this.ticketSessionId,
					ticketId: this.ticketId,
					feedbackContent: this.ticketContent,
					topicType: topicType
				})
				if (response && response?.code === 200) {

					if (window.Android && typeof window.Android.onEvent === 'function') {
						window.Android.onEvent('feedbackSubmitSuccess', '')
					} else if (window.chrome && window.chrome.webview) {
						window.chrome.webview.postMessage(JSON.stringify({
							type: 'feedbackSubmitSuccess'
						}));
					} else {
						showToast('提交成功')
					}

					return
				}


				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showToast', JSON.stringify({msg: '提交失败'}))
				}
					// else if (window.chrome && window.chrome.webview) {
					// 	window.chrome.webview.postMessage(JSON.stringify({
					// 		type: 'showToast',
					// 		msg: '提交失败'
					// 	}));
				// }
				else {
					showToast('提交失败')
				}

			} finally {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'onSubmitFeedback'}))
				}
					// else if (window.chrome && window.chrome.webview) {
					// 	window.chrome.webview.postMessage(JSON.stringify({
					// 		type: 'hideLoading',
					// 		tag: 'onSubmitFeedback'
					// 	}));
				// }
				else {
					hideLoading()
				}
			}

		},
		onImageClicked(imageUrl) {
			if (!imageUrl) {
				return
			}
			this.imageUrl = imageUrl
			this.showImage = true
		},
		onImageViewerClosed() {
			this.imageUrl = null
			this.showImage = false
		},
		onDeleteImage(imageUrl) {
			this.showConfirmModal = true
			this.waitForDeleteUrl = imageUrl
		},
		onModalCancel() {
			this.showConfirmModal = false
		},
		async confirmDeleteImage() {
			if (!this.waitForDeleteUrl) {
				return
			}
			try {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showLoading', JSON.stringify({tag: 'confirmDeleteImage'}))
				}
					// else if (window.chrome && window.chrome.webview) {
					// 	window.chrome.webview.postMessage(JSON.stringify({
					// 		type: 'showLoading',
					// 		tag: 'confirmDeleteImage'
					// 	}));
				// }
				else {
					showLoading()
				}
				const response = await postRequestAsync('customerServicePanel/deleteFeedbackImage', {
					ticketSessionId: this.ticketSessionId,
					feedbackTopicId: this.ticketId,
					imageUrl: this.waitForDeleteUrl
				})
				if (response && response?.code === 200) {

					this.ticketImageList = response.msg


					if (window.Android && typeof window.Android.onEvent === 'function') {
						window.Android.onEvent('showToast', JSON.stringify({msg: '删除成功'}))
					}
						// else if (window.chrome && window.chrome.webview) {
						// 	window.chrome.webview.postMessage(JSON.stringify({
						// 		type: 'showToast',
						// 		msg: '删除成功'
						// 	}));
					// }
					else {
						showToast('删除成功')
					}

					this.showConfirmModal = false

				} else {

					if (window.Android && typeof window.Android.onEvent === 'function') {
						window.Android.onEvent('showToast', JSON.stringify({msg: '删除失败'}))
					}
						// else if (window.chrome && window.chrome.webview) {
						// 	window.chrome.webview.postMessage(JSON.stringify({
						// 		type: 'showToast',
						// 		msg: '删除失败'
						// 	}));
					// }
					else {
						showToast('删除失败')
					}

				}
			} finally {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'confirmDeleteImage'}))
				}
					// else if (window.chrome && window.chrome.webview) {
					// 	window.chrome.webview.postMessage(JSON.stringify({
					// 		type: 'hideLoading',
					// 		tag: 'confirmDeleteImage'
					// 	}));
				// }
				else {
					hideLoading()
				}
			}

		}
	}
}
</script>
<style scoped>
.create-feedback-title {
	font-size: 24px;
	color: rgba(230, 213, 184, 1);
	text-align: start;
	padding-bottom: 8px;
	width: 100%;
}

.create-feedback-sub-title {
	color: #E45826;
	font-size: 16px;
	width: 100%;
	text-align: start;
}

hr {
	border: none;
	height: 1px;
	background-color: rgba(230, 213, 184, 1);
	margin: 20px 0;
	width: 100%;
}

.feedback-topic-card {
	font-size: 16px;
	color: rgba(230, 213, 184, 1);
	padding: 8px;
	border: 1px solid rgba(230, 213, 184, 1);
	border-radius: 4px;
	text-align: start;
	background-color: rgba(230, 213, 184, 0.2);
	margin: 8px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.topic-list-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.feedback-topic-label {
	line-height: 1;
}

.feedback-topic-description {
	width: 100%;
	text-align: start;
	color: rgba(230, 213, 184, 1);
	white-space: pre-wrap;
	font-size: 16px;
}

.feedback-topic-solution {
	width: 100%;
	text-align: start;
	color: rgba(230, 213, 184, 1);
	font-size: 16px;
	white-space: pre-wrap;
}

textarea {
	width: 100%;
	background-color: transparent;
	border: 1px solid rgba(230, 213, 184, 1);
	border-radius: 4px;
	margin-top: 8px;
	font-size: 16px;
	color: rgba(230, 213, 184, 1);
	min-height: 6em;
}

/* Styling the placeholder */
textarea::placeholder {
	color: #ff6b6b; /* Change placeholder color */
	font-style: italic; /* Make placeholder italic */
	opacity: 0.7; /* Adjust opacity */
}

/* For older browsers */
textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: rgba(230, 213, 184, 0.8);
	font-style: italic;
	opacity: 0.7;
}

textarea::-moz-placeholder { /* Firefox 19+ */
	color: rgba(230, 213, 184, 0.8);
	font-style: italic;
	opacity: 0.7;
}

textarea:-ms-input-placeholder { /* IE 10+ */
	color: rgba(230, 213, 184, 0.8);
	font-style: italic;
	opacity: 0.7;
}

textarea:-moz-placeholder { /* Firefox 18- */
	color: rgba(230, 213, 184, 0.8);
	font-style: italic;
	opacity: 0.7;
}

.feedback-btn-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 3em 0;
}

.feedback-last-step {
	color: rgba(230, 213, 184, 1);
	font-size: 18px;
	line-height: 1;
	padding: 4px 8px;
	border: 1px solid rgba(230, 213, 184, 1);
	border-radius: 4px;
	cursor: pointer;
}

.feedback-submit-btn {
	color: var(--background-color);
	font-size: 18px;
	line-height: 1;
	padding: 4px 8px;
	border: 1px solid rgba(230, 213, 184, 1);
	border-radius: 4px;
	background-color: rgba(230, 213, 184, 1);
	cursor: pointer;
}


.feedback-add-attachments {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 8px;
}

.feedback-add-attachments-label {
	color: rgba(230, 213, 184, 1);
	font-size: 16px;
	width: 100%;
	text-align: start;
}

.upload-btn-container-image {
	width: 32px;
	cursor: pointer;
}


.upload-btn-container {
	border: 1px solid #E6D5B8;
	width: 10em;
	height: 10em;
	margin: 0.6em 0;
	border-radius: 0.2em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 0.4em;
	box-sizing: border-box;
}

.upload-btn-container-text {
	color: #E6D5B8;
	font-size: 14px;
}

.upload-progress-bar-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
}

.upload-progress-text {
	color: #E6D5B8;
}

.upload-progress-bar-bg {
	width: 100%;
	height: 5px;
	background-color: #695b44;
	position: relative;
}

.upload-progress-bar {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #E6D5B8;
	height: 5px;
}

.uploaded-delete-image {
	position: absolute;
	top: 0.2em;
	right: 0.2em;
	width: 2em;
	opacity: 0.8;
	cursor: pointer;
}

</style>